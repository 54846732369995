import React, { useRef, useState, useEffect, useContext } from 'react';
import { Link, useLocation } from "react-router-dom";
import { WalletContext } from '../../context/WalletContext';
import menus from "../../pages/menu";

import logoheader from '../../assets/images/logo/logo.png'
import logoheader2x from '../../assets/images/logo/logo@2x.png'
import logodark from '../../assets/images/logo/logo_dark.png'
import logodark2x from '../../assets/images/logo/logo_dark@2x.png' 
import avt from '../../assets/images/icon/user.png'
import creatureOfBoneX from '../../assets/images/logo/CreatureBonex.png'

const Header = () => {
    const { pathname } = useLocation();
    const { isLoggedIn, login, logout } = useContext(WalletContext);
    const userVerified = localStorage.getItem('userVerified') === 'true';
    const walletAddress = localStorage.getItem('walletAddress');

    const handleLogout = (e) => {
        e.preventDefault();
        logout();
    };

    const headerRef = useRef(null);
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('.js-header');
        const scrollTop = window.scrollY;
        scrollTop >= 300 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
        scrollTop >= 400 ? header.classList.add('is-small') : header.classList.remove('is-small');
    };

    const menuLeft = useRef(null);
    const btnToggle = useRef(null);

    const menuToggle = () => {
        menuLeft.current.classList.toggle('active');
        btnToggle.current.classList.toggle('active');
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleOnClick = index => {
        setActiveIndex(index);
    };

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    function shortenAddress(walletAddress) {
        if (!walletAddress || walletAddress.length < 10) {
            return walletAddress;
        }
        return walletAddress.substring(0, 4) + '...' + walletAddress.substring(walletAddress.length - 2);
    }

    const shortWalletAddress = shortenAddress(walletAddress);

    return (
        <header id="header_main" className="header_1 js-header" ref={headerRef}>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div id="site-header-inner">
                            <div className="wrap-box flex">
                                <div id="site-logo" className="clearfix">
                                    <div id="site-logo-inner">
                                        <Link to="/" rel="home" className="main-logo">

                                            <img className='logo-dark'  id="logo_header" src={logodark} srcSet={`${logodark2x}`} alt="zonex-logo" /> 
                                        </Link>  
                                        <a className='main-logo bonex-logo' href="https://bonex.net/" target="_blank" rel="noopener noreferrer"> 
                                            <img className='logo-dark'id="logo_header" src={creatureOfBoneX} alt="Bonex Logo" />
                                        </a> 
                                    </div>
                                </div>
                                <div className="mobile-button" ref={btnToggle} onClick={menuToggle}><span></span></div>
                                <nav id="main-nav" className="main-nav flex justify-center w-full" ref={menuLeft} >

                                    <ul id="menu-primary-menu" className="menu">
                                        {
                                            menus.map((data, index) => (
                                                <li key={index} onClick={() => handleOnClick(index)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === index ? 'active' : ''}`}>
                                                    <Link to={data.links}>{data.name}</Link>
                                                    {
                                                        data.namesub &&
                                                        <ul className="sub-menu">
                                                            {
                                                                data.namesub.map((submenu) => (
                                                                    <li key={submenu.id} className={
                                                                        pathname === submenu.links
                                                                            ? "menu-item current-item"
                                                                            : "menu-item"
                                                                    }><Link to={submenu.links}>{submenu.sub}</Link></li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </nav>

                                <div className="flat-search-btn flex">
                                    <div className="sc-btn-top mg-r-12" id="site-header">
                                        {isLoggedIn && walletAddress ? (
                                            // If the wallet is connected, display wallet info
                                            <div className="wallet-info">
                                                <div className="admin_active" id="header_admin">
                                                    <div className="header_avatar" onClick={togglePopup}>
                                                        {/* Display dynamic balance (assuming walletBalance is in SOL) */}
                                                        <div className="price">
                                                            <span>{userVerified ? `${shortWalletAddress}` : 'Address'} </span>
                                                        </div>
                                                        <img className="avatar" src={avt} alt="avatar" />
                                                        {/* Wallet address and other user actions */}
                                                        <div className={`avatar_popup mt-20 ${isPopupVisible ? 'visible' : ''}`}>
                                                            <div className="d-flex align-items-center copy-text justify-content-between">
                                                                {/* Shortened wallet address */}
                                                                <span>{`${shortWalletAddress}`}</span>
                                                                <Link to="#" onClick={(e) => {
                                                                    e.preventDefault(); // Prevent navigation
                                                                    navigator.clipboard.writeText(walletAddress); // Copy wallet address to clipboard
                                                                }}>
                                                                    <i className="ml-2 fal fa-copy"></i>
                                                                </Link>
                                                            </div>
                                                            <div className="d-flex align-items-center mt-10 balance-dark">
                                                                <i className="fal fa-check-circle"></i>
                                                                <div className="info ml-10">
                                                                    <p className="text-sm font-book text-gray-400 balance-dark ">Account:</p>
                                                                    {/* Dynamically display the wallet balance or a placeholder if it's not available */}
                                                                    <p className="w-full text-sm font-bold text-green-500 balance-dark">
                                                                        {userVerified ? `Verified` : 'Not Verified'}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            {/* Divider */}
                                                            <div className="hr balance-dark-bg mt-10"></div>

                                                            {/* User action links */}
                                                            <div className="links mt-10">
                                                                <a className="mt-10" href="/login" onClick={handleLogout} id="logout">
                                                                    <i className="fal fa-sign-out"></i> <span> Logout</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            // If the wallet is not connected, show the 'Wallet Connect' button
                                            <button onClick={login} className="sc-button header-slider style style-1 wallet fl-button pri-1">
                                                <span>Wallet connect</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
