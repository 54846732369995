import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import shape1 from "../../assets/images/backgroup-secsion/bg-gradient1.png";
import shape2 from "../../assets/images/backgroup-secsion/bg-gradient2.png";
import shape3 from "../../assets/images/backgroup-secsion/bg-gradient3.png";

const Slider = (props) => {
  const data = props.data;
  return (
    <div className="mainslider">
      <Swiper modules={[Navigation, Scrollbar, A11y]} spaceBetween={0} slidesPerView={1} navigation scrollbar={{ draggable: true }}>
        {data.map((item, index) => (
          <SwiperSlide key={index} className={item.class}>
            <SliderItem item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Slider.propTypes = {
  data: PropTypes.array.isRequired,
  control: PropTypes.bool,
  auto: PropTypes.bool,
  timeOut: PropTypes.number
};
const SliderItem = (props) => {
  const isMintButtonVisible = () => {
    const now = new Date();
    const startDate = new Date("2024-05-11T17:00:00+03:00"); // EEST
    const endDate = new Date("2024-05-12T00:00:00+03:00"); // EEST

    return now >= startDate && now <= endDate;
  };

  return (
    <div className="flat-title-page test">
      <img className="bgr-gradient gradient1" src={shape1} alt="Axies" />
      <img className="bgr-gradient gradient2" src={shape2} alt="Axies" />
      <img className="bgr-gradient gradient3" src={shape3} alt="Axies" />
      <div className="shape item-w-16"></div>
      <div className="shape item-w-22"></div>
      <div className="shape item-w-32"></div>
      <div className="shape item-w-48"></div>
      <div className="shape style2 item-w-51"></div>
      <div className="shape style2 item-w-51 position2"></div>
      <div className="shape item-w-68"></div>
      <div className="overlay"></div>
      <div className="swiper-container mainslider home">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="slider-item">
              <div className="themesflat-container">
                <div className="wrap-heading flat-slider flex">
                  <div className="image image2 alien-smallscreen">
                    <img className="img-bg" src={props.item.img} alt="ZoneXAlien" />
                  </div>
                  <div className="content">
                    <div className="center">
                      <h2 className="heading">{props.item.title_1}</h2>
                      <h1 className="heading mb-style">
                        <span className="tf-text s1">{props.item.title_2}</span>
                      </h1>
                      <h1 className="heading">{props.item.title_3}</h1>
                      <p className="sub-heading">{props.item.description}</p>
                      <div className="flat-bt-slider flex style2">
                        {isMintButtonVisible() ? (
                          <>
                            <Link to="https://mint.zonexlegacy.com" className="sc-button header-slider style style-1 rocket fl-button pri-1">
                              <span> Mint your NFT</span>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link to="/roadmap" className="sc-button header-slider style style-1 rocket fl-button pri-1">
                              <span> Roadmap</span>
                            </Link>
                            <Link to="/storyline" className="sc-button header-slider style style-1 note fl-button pri-1">
                              <span> Storyline</span>
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="image image2 spacecraft-smallscreen">
                    <img className="img-bg" src={props.item.img2} alt="ZoneXSpaceship" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Slider;
