import React , {useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import imgblog1 from '../assets/images/blog/thumb-7.jpg'
import imgblog2 from '../assets/images/blog/thumb-6.jpg'
import imgblogdetail1 from '../assets/images/blog/thumb1_details.jpg'
import imgblogdetail2 from '../assets/images/blog/thumb2_details.jpg'

const AboutUs = () => { 
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">About Us</h1>
                            </div> 
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-contact tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="terminal"> 
                                <h2 className="text-2xl font-semibold mb-2">Welcome to ZoneX Legacy</h2>
                                <p className="mb-5">At the heart of the innovative and ever-evolving world of digital art and blockchain technology lies ZoneX Legacy, a pivotal extension of the BoneX Margin platform. ZoneX Legacy is not just a marketplace; it's a portal to a universe where art, technology, and community converge.</p>
                                
                                <h2 className="text-2xl font-semibold mb-2">Our Journey with BoneX Margin</h2>
                                <p className="mb-5">Born from the pioneering spirit of BoneX Margin, ZoneX Legacy stands as a testament to our commitment to expanding the horizons of digital interaction and NFT (Non-Fungible Token) commerce. BoneX Margin's expertise in creating dynamic digital platforms provided the perfect foundation for ZoneX Legacy to flourish. We share a vision of leveraging blockchain technology to revolutionize the way people experience art and collectibles.</p>

                                <h2 className="text-2xl font-semibold mb-2">Inspiration and Mission</h2>
                                <p className="mb-1">Inspired by the mysteries and allure of extraterrestrial life, ZoneX Legacy delves into the fascinating realm of alien-themed narratives. Our platform is a tribute to the infinite possibilities that the universe holds, and through our NFTs, we aim to ignite imagination and curiosity. At ZoneX Legacy, each NFT is more than just a digital asset; it's a piece of a larger cosmic story waiting to be explored.</p>
                                <p className="mb-5">Our mission is to create a space where art enthusiasts and collectors can immerse themselves in a unique digital experience, owning and interacting with NFTs that are not just visually stunning but also rich in story and character.</p>

                                <h2 className="text-2xl font-semibold mb-2">Future Plans</h2>
                                <p className="mb-1">Looking ahead, ZoneX Legacy is poised for growth and innovation. We envision a future where our platform is a leading force in the NFT space, continually introducing new features and experiences that push the boundaries of digital art and interaction</p>
                                <p className="mb-1"><strong> 1. Expansion of NFT Collections:</strong> We plan to diversify our NFT offerings, introducing new characters, stories, and interactive features that will enhance the collector experience.</p>
                                <p className="mb-1"><strong> 2. Community Engagement:</strong> Central to our ethos is the development of a vibrant community. We aim to foster a space for discussions, collaborations, and events that bring NFT enthusiasts and creators together.</p>
                                <p className="mb-1"><strong> 3. Technological Advancements:</strong> Keeping pace with technological advancements, we are committed to integrating the latest blockchain features that ensure security, scalability, and an enhanced user experience.</p>
                                <p className="mb-1"><strong> 4. Collaborations and Partnerships:</strong> We are always on the lookout for collaborative opportunities with artists, tech innovators, and other platforms to enrich our offerings and reach a wider audience.</p> 
                                <p className="mb-5"><strong> 5. Collaborations and Partnerships:Collaborations and Partnerships:</strong> As we grow, we pledge to consider the environmental impact of blockchain technology and explore sustainable practices to reduce our carbon footprint.</p> 

                                <h2 className="text-2xl font-semibold mb-2">Join Us on This Journey</h2>
                                <p className="mb-5">As ZoneX Legacy continues to evolve, we invite you to be a part of this exciting journey. Whether you are an artist, a collector, or simply a curious explorer of the digital realm, there's a place for you in the ZoneX community. Let's embark on this adventure together, pushing the limits of imagination and technology.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
export default AboutUs;
