import React from 'react';
import img from '../../../assets/images/box-item/StorylineImage.jpg'  

const StoryLine = () => {

    return (
        <div>
            <section className="tf-contact tf-section primary-colour">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <h2 className="tf-title ">Storyline</h2>
                                <div className='heading-line'></div> 
                            </div>
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="box-feature-contact">
                                <img src={img} alt="Axies" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 text-center">
                            <h2 className="tf-title-heading style-2 mg-bt-12">
                                ZoneX: Legacy of the Ages
                            </h2>
                            <h5 className="sub-title style-1">
                            <p>After World War II, ZoneX, aliens with a vision for a peaceful and united humanity, arrived on Earth. Their mission was to address the root causes of human conflict, particularly the uneven distribution of resources. To this end, they created five clones, each placed in different global hotspots to foster change and guide humanity toward unity and economic parity.</p>
                            <p>The clones, working in critical areas from the USA to Japan, subtly influenced key events and people, promoting cooperation, peace, and equitable growth. Their efforts, aligned with ZoneX's vision, gradually led to shifts in global attitudes and policies, particularly with the advent of Bitcoin and the digital era, which presented new opportunities for economic equality and collective progress.</p>
                            <p>The legacy of ZoneX and his clones culminated in the "ZoneX Chronicles" NFT collection, symbolizing the transformative journey from division to unity. Each NFT not only served as a piece of digital art but also as a narrative of hope and change, reflecting an alternate history shaped by the visionary efforts of an extraterrestrial and his human proxies, echoing the potential for harmony and equitable resource distribution on Earth.</p>
                            </h5>  
                            <div className="center">
                                <div className="meta-info style2">
                                    <a href="/storyline" className="sc-button header-slider style rocket fl-button pri-3 main-btn no-bg"><span>Explore</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default StoryLine;
