import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import img from "../../../assets/images/icon/crown-white.png";
import imgCheck from "../../../assets/images/icon/check.png";

const Roadmap = () => {
  const eventArray = [
    { date: "March 20, 2024", event: "Galactic Launch - The ZoneX Legacy website goes live, opening portals to our alien universe.", completed: true },
    { date: "4th Week of April, 2024", event: "Community Honor Drop - An exclusive 20% NFT drop to celebrate our early Earthling supporters.", completed: true },
    { date: "1st Week of May, 2024", event: "Whitelist Minting Event - A special 20% minting for whitelisted members, the chosen few.", completed: true },
    { date: "1st Week of May, 2024", event: "Public Minting Invasion - 60% of our unique NFTs become available to the broader universe.", completed: true },
    { date: "3rd Week of May, 2024", event: "Telepathy Room & Marketplace Launch - For interstellar communication and trade among the stars.", completed: true },
    { date: "4th Week of May, 2024", event: "Auction Upgrade - Boosting the trading experience across galaxies.", completed: true },
    { date: "4th Week of May, 2024", event: "Telepathy Room Enhancement - Generals now can send cosmic messages to Soldiers, deepening galactic connections.", completed: true },
    { date: "1st Week of June, 2024", event: "Covert Missions Begin - Embark on adventurous quests adding depth to the ZoneX experience.", completed: true },
    { date: "2nd Week of June, 2024", event: "Charity Drops - 50% of profits donated to stellar causes.", completed: true },
    { date: "1st Week of July, 2024", event: "ZoneX Venture Start - A cosmic venture initiative, with 50% of profits fueling our intergalactic ambitions.", completed: true },
    { date: "3th Week of July, 2024", event: "Meme Coin Launch & Distribution: Launching ZoneX Memecoin, exclusively distributed to our NFT holders, enhancing our digital universe with innovative cryptocurrency.", completed: true },
    { date: "3th Week of July, 2024", event: "Meme Coin Exchange Listing", completed: true },
    { date: "Cooming Soon...", event: "Phase 2", completed: false },
  ];

  return (
    <div>
      <section className="tf-contact tf-section primary-bg">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <VerticalTimeline>
                {(eventArray || []).map((item, key) => (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: "rgb(55, 52, 89)", color: "#fff" }}
                    contentArrowStyle={{ borderRight: "7px solid  rgb(55, 52, 89)" }}
                    iconStyle={{ background: "rgba(21,20,30,255)", color: "#fff" }}
                    icon={<img src={item.completed ? imgCheck : img} alt="icon" />}
                  >
                    <h3 className="vertical-timeline-element-title">{item.date}</h3>
                    <p className="roadmap-p-size">{item.event}</p>
                  </VerticalTimelineElement>
                ))}
                <VerticalTimelineElement iconStyle={{ background: "rgba(21,20,30,255)", color: "#fff" }} icon={<img src={img} alt="icon" />} />
              </VerticalTimeline>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Roadmap;
