import './App.css';
import {   Routes , Route } from 'react-router-dom';
import routes from './pages/index'
import React , { useEffect } from 'react';
import Error404 from './pages/Error404';

function App() {
    useEffect(() => {
        const body = document.body;
        const darkTheme = "is_dark";
    
        // Removing the light theme class if present
        body.classList.remove("light");
    
        // Adding the dark theme class
        body.classList.add(darkTheme);
    
        localStorage.setItem("theme", "is_dark");
    
      }, []);
    return (
        <Routes >
            {
            routes.map((data,index) => (
                <Route onUpdate={() => window.scrollTo(0, 0)} exact={true} path={data.path} element={data.component} key={index} />
            ))
            }
             <Route path="*" element={<Error404 />} />
      </Routes>
    );
}

export default App;
