import React , { useState , Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'; 
import CardModalSell from './CardModalSell';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import img1 from '../../assets/images/box-item/image-box-26.jpg' 
import imga1 from '../../assets/images/avatar/avt-11.jpg' 

const MyNFTs = () => {

    const navigate = useNavigate();

    const [data] = useState(
        [
            {
                img: img1,
                title: "Hamlet Contemplates Lorem Ipsum...", 
                imgAuthor: imga1,
                nameAuthor: "SalvadorDali", 
            },

        ]
    )
    
    const [modalShow, setModalShow] = useState(false);

    return (
        <Fragment>
            <section className="tf-section live-auctions">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-live-auctions">
                                <h2 className="tf-title pb-20">
                                    My ZoneX NFTs</h2>
                                <Link to="/marketplace" className="exp style2">EXPLORE MORE</Link>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={30}

                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                    slidesPerView: 2,
                                    },
                                    991: {
                                    slidesPerView: 3,
                                    },
                                    
                                }}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                            >
                                    {
                                        data.map((item,index) => (
                                            <SwiperSlide key={index}>
                                                <div className="swiper-container show-shadow carousel auctions">
                                                    <div className="swiper-wrapper">
                                                        <div className="swiper-slide">
                                                            <div className="slider-item">										
                                                                <div className="sc-card-product">
                                                                    <div className="card-media">
                                                                        <Link to="/nft-details"><img src={item.img} alt="axies" /></Link> 
                                                                        <div className="button-place-bid">
                                                                            <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Sell</span></button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-title">
                                                                        <h5><Link to="/nft-details">"{item.title}</Link></h5> 
                                                                    </div>
                                                                    <div className="meta-info">
                                                                        <div className="author"> 
                                                                            <div className="info">
                                                                                <span>Owner</span>
                                                                                <h6> {item.nameAuthor} </h6>
                                                                            </div>
                                                                        </div> 
                                                                    </div> 
                                                                    <div className="card-bottom">
                                                                    <button onClick={() => setModalShow(true)} className="sc-button style bag fl-button pri-3 no-bg"><span>Sell</span></button>
                                                                    <button onClick={() => navigate('/telepathy-room')}  className="sc-button style note fl-button pri-3 no-bg"><span>Chat</span></button>    
                                                                    </div>
                                                                </div>    	
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <CardModalSell
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>
    );
}


export default MyNFTs;
