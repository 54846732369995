import React from 'react'; 
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const TermsAndConditions = () => {
    return (
        <div className='terminal-page-container'>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Terms & Conditions</h1>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-contact tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="terminal">

                            <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
                                <p className="mb-1"> &#x2022; Welcome to Zonex Legacy, a digital marketplace on the Solana network specializing in unique Non-Fungible Tokens (“NFTs”) inspired by an alien-themed narrative. These Terms ofConditions (“Terms”) outline your use of Zonex Legacy and its services. By interacting with our platform, you agree to these Terms and our Privacy Policy.</p>
                                <p className="mb-1"> &#x2022; The Zonex Legacy allows you to sell, purchase, and trade NFTs through the Solana blockchain. The rules for the provided services are available in the provided Terms. In order for you to access the services, you will need to connect and unlock your digital wallets with Phantom or any other wallet that supports WalletConnect Protocol.</p>
                                <p className="mb-5"> &#x2022; The Zonex Legacy does not operate as a wallet provider, exchange, broker, financial institution, or creditor. We do not have custody or control over the NFTs or blockchains you are interacting with. To use our Service, you must use a third-party wallet that allows you to engage in transactions on blockchains.</p>
 
                            <h2 className="text-2xl font-semibold mb-2">2. Description of Service</h2>
                                <p className="mb-5">Zonex Legacy provides a platform for buying, selling, and trading NFTs (“Services”), featuring a distinctive alien storyline. Transactions are facilitated directly through users' digital wallets on the Solana blockchain, eliminating the need for traditional user accounts.</p>

                            <h2 className="text-2xl font-semibold mb-2">3. Wallet-Based Authentication and Use</h2>
                                <p className="mb-1"> <strong>3.1.</strong> Users interact with Zonex Legacy services through a wallet-based authentication system.</p>
                                <p className="mb-1"> <strong>3.2.</strong> You are responsible for maintaining the security of your wallet and any transactions made through it.</p>
                                <p className="mb-5"> <strong>3.3.</strong> Zonex Legacy is not responsible for losses incurred due to compromised wallet security or unauthorized use.</p>
 
                            <h2 className="text-2xl font-semibold mb-2">NFT Interaction and Chat Functionality</h2>
                                <p className="mb-1"> <strong>4.1.</strong> Your blockchain address functions as your identity on the Zonex Legacy. You will be required to obtain or have a blockchain address and a third-party wallet in order to access the Services. Your use of the Services on Zonex Legacy will be associated with your linked blockchain address and/or wallet and display the NFTs for that blockchain address. By using your wallet in connection with Zonex Legacy, you agree that you are using that wallet under the terms and conditions of the applicable provider of the wallet. Wallets are not operated by, maintained by, or affiliated with Zonex Legacy, and we do not have custody or control over the contents of your wallet and have no ability to retrieve or transfer its contents. Zonex Legacy accepts no responsibility for, or liability to you, in connection with your use of a wallet and makes no representations or warranties regarding how the platform will operate with any specific wallet. You are solely responsible for keeping your wallet secure and you should never share your wallet credentials or seed phrase with anyone. If you discover an issue related to your wallet, please contact your wallet provider.</p>
                                <p className="mb-1"> <strong>4.2.</strong> Likewise, you are solely responsible for your use of the Services and any associated wallet and we are not liable for any acts or omissions by you in connection with your wallet being compromised. You agree to immediately notify us if you discover or otherwise suspect any security issues related to the platform.</p>
                                <p className="mb-1"> <strong>4.3.</strong> You also represent and warrant that you will comply with all applicable and enforceable laws (e.g., local, state, federal and other laws) when using Zonex Legacy’s Services.</p>
                                <p className="mb-1"> <strong>4.4.</strong> Without limiting the foregoing, by using the platform, you represent and warrant that:</p>
                                <p className="mb-1"> <strong>(a)</strong> You are not located in, under the control of, or a national or resident of any country to which the United States has embargoed goods or services;</p>
                                <p className='mb-1'> <strong>(b)</strong> you have not been identified as a Specially Designated National or placed on any U.S. Government list of prohibited, sanctioned, or restricted parties.</p>
                                <p className="mb-1"> <strong>4.5.</strong> If you access or use the Platform outside the United States, you are solely responsible for ensuring that your access and use of the Platform in such country, territory or jurisdiction does not violate any applicable laws.</p>
                                <p className="mb-1"> <strong>4.6.</strong> Owners of Zonex Legacy NFTs can engage with their NFTs via an innovative chat feature.</p>
                                <p className="mb-1"> <strong>4.7.</strong> Chat Functionality Limits: NFT holders can interact with their NFTs through a chat feature, subject to daily message limits based on the NFT's rank:</p>
                                <p className="mb-1"> <strong>&#x2022; Soldier:</strong> 1 message per day</p>
                                <p className="mb-1"> <strong>&#x2022; Sergeant:</strong> 2 messages per day</p>
                                <p className="mb-1"> <strong>&#x2022; Officer:</strong> 3 messages per day</p>
                                <p className="mb-1"> <strong>&#x2022; Lieutenant:</strong> 4 messages per day</p>
                                <p className="mb-1"> <strong>&#x2022; Colonel:</strong> 5 messages per day</p>
                                <p className="mb-5"> <strong>&#x2022; General:</strong> 6 messages per day</p>
                                

                            <h2 className="text-2xl font-semibold mb-2">5. Cross-Marketplace Listings</h2>
                                <p className="mb-1"> <strong>5.1.</strong> Zonex Legacy NFTs can be listed for sale on other marketplaces.</p>
                                <p className="mb-1"> <strong>5.2.</strong> Users must adhere to the terms of both Zonex Legacy and the other marketplaces when listing.</p>
                                <p className="mb-5"> <strong>5.3.</strong> Zonex Legacy assumes no responsibility for transactions or disputes on external platforms.</p>
                            
                            <h2 className="text-2xl font-semibold mb-2">6. Royalty Fees</h2>
                                <p className="mb-1"> <strong>6.1.</strong> Each NFT sale includes a 7% royalty fee, automatically allocated to the creator of the NFT. This ensures that authors receive recognition and reward for their work, fostering a sustainable and creative community.</p>
                                <p className="mb-5"> <strong>6.2.</strong> This fee supports the ongoing development and maintenance of Zonex Legacy.</p>
                                

                            <h2 className="text-2xl font-semibold mb-2">7. Intellectual Property Rights</h2>
                                <p className="mb-1">Unless otherwise indicated in writing by us, content and other materials contained on the platform, including, without limitation, the Zonex Legacy logo and pages design, texts, graphics, pictures, information, data, software, sound files, promotional or other files and the selection and arrangement thereof (collectively, "Content"), the platform as a whole, and any NFTs are the proprietary property of Zonex Legacy. </p>
                                <p className="mb-1">Notwithstanding anything to the contrary in these Terms, Zonex Legacy and the Content therein may include software components provided by Zonex Legacy or its affiliates or a third party that are subject to separate license terms, in which case those license terms will govern such software components.</p>
                                <p className="mb-5">The Zonex Legacy logo and any Zonex Legacy product, including service names, logos or slogans that may appear on the platform are the exclusive property of Zonex Legacy or our affiliates and may not be copied, imitated, or used, in whole or in part, without our prior written permission. You will not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast, or otherwise exploit the platform. You may not use any metatags or other "hidden text" utilizing "Zonex Legacy" or any other name, trademark, product, or service name of Zonex Legacy or our affiliates without our prior written permission. All other trademarks, registered trademarks, product names, or logos mentioned on the platform are the property of their respective owners and may not be copied, imitated, or used, in whole or in part, without the permission of the applicable trademark holder. Reference to any products, services, processes, or other information by name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship, or recommendation by Zonex Legacy.</p>

                            <h2 className="text-2xl font-semibold mb-2">8. Transactions and Responsibilities</h2>
                                <p className="mb-1"> <strong>8.1.</strong> Users are solely responsible for the legality and accuracy of their transactions.</p>
                                <p className="mb-1"> <strong>8.2.</strong> Transactions that take place on the platform are managed and confirmed via the Solana blockchain. By accepting the current Terms you agree that your wallet address will be made publicly visible whenever you engage in a transaction on the platform. We neither own nor control any other third-party site, product, or service that you might access, visit, or use for the purpose of enabling you to use the various features of the platform. We will not be liable for the acts or omissions of any such third parties, nor will we be liable for any damage that you may suffer as a result of your transactions or any other interaction with any such third parties.</p>
                                <p className="mb-5"> <strong>8.3.</strong> Zonex Legacy serves as a facilitator and is not a party in user transactions.</p>
                            
                            <h2 className="text-2xl font-semibold mb-2">9. Disclaimers and Limitation of Liability</h2>
                                <p className="mb-1"> <strong>9.1.</strong> Zonex Legacy is provided on an "as is" basis without warranties of any kind.</p>
                                <p className="mb-1"> <strong>9.2.</strong> To the fullest extent permitted by law, in no event will Zonex Legacy be liable to you or any third party for any lost profit or any indirect, consequential, exemplary, incidental, special, or punitive damages arising from the use of the platform, products or third party sites and products, or for any damages related to loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, or loss of data, and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable and even if Zonex Legacy has been advised of the possibility of such damages. Access to, and use of, the platform, products, or third-party sites and products are at your own discretion and risk, and you will be solely responsible for any damage to your computer system or mobile device or loss of data resulting therefrom.</p>
                                <p className="mb-5"> <strong>9.3.</strong> Notwithstanding anything to the contrary contained herein, in no event shall the maximum aggregate liability of Zonex Legacy arising out of or in any way related to the platform, the access to and use of it, content, crypto assets, or any products or services purchased on it.</p>
                            
                            <h2 className="text-2xl font-semibold mb-2">10. Assumption of Risk</h2>
                            <h4 className="text-2xl font-semibold mb-2">You accept and acknowledge:</h4>
                                <p className="mb-1"> <strong>&#x2022; A.</strong> The prices of blockchain assets are extremely volatile. Fluctuations in the price of other digital assets could materially and adversely affect the crypto assets, which may also be subject to significant price volatility. We cannot guarantee that any purchasers of crypto assets will not incur a loss of money.</p>
                                <p className="mb-1"> <strong>&#x2022; B.</strong> You are solely responsible for determining what, if any, taxes apply to your transactions. We will not be responsible for determining the taxes that apply to individual or collective crypto assets transactions.</p>
                                <p className="mb-1"> <strong>&#x2022; C.</strong> Zonex Legacy does not store, send, or receive crypto assets.</p>
                                <p className="mb-1"> <strong>&#x2022; D.</strong> There are risks associated with using an Internet-based currency, including but not limited to, the risk of hardware, software, and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your wallet. You accept and acknowledge that we will not be responsible for any communication failures, disruptions, errors, distortions, or delays you may experience.</p>
                                <p className="mb-1"> <strong>&#x2022; E.</strong> The regulatory regime governing blockchain technologies, cryptocurrencies, and tokens is uncertain, and new regulations or policies may adversely affect the development of the platform and the utility of crypto assets.</p>
                                <p className="mb-5"> <strong>&#x2022; F.</strong> Zonex Legacy relies on third-party platforms such as Phantom to perform transactions. If we are unable to maintain a good relationship with such platform providers; if the terms and conditions or pricing of such platform providers change; if we violate or cannot comply with the terms and conditions of such platforms; or if any of such platforms lose market share or falls out of favour or is unavailable for a prolonged period of time, access to and use of the platform might deteriorate.</p>

                            <h2 className="text-2xl font-semibold mb-2">11. User Conduct</h2> 
                            <p className="mb-1"> <strong> You agree that you will not violate any law, contract, intellectual property, or other third-party right and that you are solely responsible for your conduct while accessing or using the platform. You agree that you will abide by these Terms and will not: </strong></p>
                                <p className="mb-1"> <strong>&#x2022; (a) </strong> use NFTs in any way that constitutes or can constitute a violation of granted rights or obligations by us or any third party;</p>
                                <p className="mb-1"> <strong>&#x2022; (b) </strong> provide false or misleading information</p>
                                <p className="mb-1"> <strong>&#x2022; (c) </strong> use the platform in any manner that could interfere, disrupt, negatively affect or inhibit other users from using the platform to its fullest degree, or that could damage, disable, overburden or impair the functioning of the platform in any manner;</p>
                                <p className="mb-1"> <strong>&#x2022; (d) </strong> reverse engineer the platform as a whole or any part or aspect of it, or otherwise take actions that might discover source code or bypass or circumvent measures employed to prevent or limit access to any area or code of the platform;</p>
                                <p className="mb-1"> <strong>&#x2022; (e) </strong> attempt to circumvent any content-filtering techniques we employ or attempt to access any feature or area of the platform that you are not authorized to access;</p>
                                <p className="mb-1"> <strong>&#x2022; (f) </strong> use any robot, spider, crawler, scraper, script, browser extension, offline reader or other automated means or interface not authorized by us to access the platform, extract data, or otherwise interfere with or modify the rendering of the platforms pages or functionality;</p>
                                <p className="mb-1"> <strong>&#x2022; (g) </strong> provide false or misleading information</p>
                                <p className="mb-1"> <strong>&#x2022; (h) </strong> use data collected from the platform for any direct marketing activity (including without limitation, email marketing, SMS marketing, telemarketing, and direct marketing);</p>
                                <p className="mb-1"> <strong>&#x2022; (i) </strong> bypass or ignore instructions that control automated access to the platform;</p>
                                <p className="mb-1"> <strong>&#x2022; (j) </strong> use the platform for any illegal or unauthorized purpose, or engage in, encourage or promote any activity that violates these Terms;</p>
                                <p className="mb-1"> <strong>&#x2022; (k) </strong> engage in any coercive, deceptive, and/or manipulative behaviour concerning any of the Services provided by Zonex Legacy, including using coercive, deceptive, and/or manipulative bidding tactics;</p>
                                <p className="mb-1"> <strong>&#x2022; (l) </strong> post false, inaccurate, misleading, deceptive, defamatory, or libellous content;</p> 
                                <p className="mb-1"> <strong>&#x2022; (m) </strong> distribute or post spam, unsolicited or bulk electronic communications, chain letters, or pyramid schemes;</p>
                                <p className="mb-1"> <strong>&#x2022; (n) </strong> distribute viruses or any other technologies that may harm the platform or the interests or property of users;</p>
                                <p className="mb-1"> <strong>&#x2022; (o) </strong> post any hateful content;</p>
                                <p className="mb-5"> <strong>&#x2022; (p) </strong> use the platform to carry out any illegal activities, including but not limited to money laundering, terrorist financing or deliberately engaging inactivities designed to adversely affect the performance of the platform.</p>

                            <h2 className="text-2xl font-semibold mb-2">12. Amendments to Terms and the Platform</h2>
                                <p className="mb-1"> <strong>12.1</strong> These Terms may be updated periodically, and continued use constitutes agreement to the new terms.</p> 
                                <p className="mb-5"> <strong>12.2</strong> We reserve the right in our sole discretion to modify, suspend, or discontinue, temporarily or permanently, the platform or any features or parts thereof at any time and without liability</p> 

                            <h2 className="text-2xl font-semibold mb-2">13. Contact Information</h2>
                                <p className="mb-5">For questions regarding these Terms, please contact us at support@zonexlegacy.com.</p>

                                <h2 className="text-2xl font-semibold mb-2">14. Miscellaneous</h2>
                                <p className="mb-5">These Terms constitute the entire agreement between you and Zonex Legacy relating to your access to and use of the platform and the Content therein. These Terms, and any rights and licenses granted hereinabove, may not be transferred or assigned by you without our prior written consent. Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not intended to confer third-party beneficiary rights upon any other person or entity.</p>
 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default TermsAndConditions;
