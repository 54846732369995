import React, { useState, useEffect, useContext, useRef } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import '../assets/css/chatComponent.css';
import '../assets/css/leftColumn.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { WalletContext } from '../context/WalletContext';
const Cookies = require('js-cookie');

SwiperCore.use([Navigation, Pagination]);
 

const LeftColumn = ({ cards, selectCard, selectedCardIndex, isVisible, setIsLeftColumnVisible }) => {
    useEffect(() => {
        const handleClick = (event) => {
            if (event.target.closest('.click')) { 
                if (window.innerWidth <= 740) {
                    setIsLeftColumnVisible(false);
                }
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [setIsLeftColumnVisible]); 

    return ( 
        <div className={`col-md-3 left-column-container`}>
            <h2 className="tf-title-heading ct style-2 mg-bt-12">My NFTs</h2>

            {!isVisible && (
                <div className="center-button-container">
                    <button className="sc-button header-slider style style-1 wallet fl-button pri-1" onClick={() => setIsLeftColumnVisible(true)}>
                        <span>View list</span>
                    </button>
                </div>
            )}

            <div className={`left-column-content ${isVisible ? '' : 'hidden'}`}>
                {cards.map((card, index) => (
                    <div key={index} className="sc-card-product card-color mb-5">
                        <div className="card-media">
                            <img src={card.image_uri} alt={card.name} />
                        </div>
                        <div className="card-body">
                            <h4 className="card-title">{card.name} _</h4>
                            <p className="card-text mb-4">{card.description}</p>
                            <button
                                className="sc-button style note fl-button pri-3 no-bg click"
                                onClick={() => { selectCard(index);   }}
                                disabled={selectedCardIndex === index}
                            >
                                <span>Chat</span>
                            </button>
                        </div>
                    </div>
                ))}
            </div> 
        </div> 
    );
};


const Typewriter = ({ text, speed = 50, onType }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [index, setIndex] = useState(0);
    const [isClassActive, setIsClassActive] = useState(true);
    const [isTyping, setIsTyping] = useState(true);
    const intervalRef = useRef();

    useEffect(() => {
        if (index < text.length) {
            intervalRef.current = setTimeout(() => {
                const nextChar = text[index];
                const nextText = displayedText + nextChar;
                const wordCount = nextText.split(' ').length;

                if (wordCount > 6) {
                    setIsClassActive(false);
                }

                if (index === 0) {
                    setIsTyping(false);
                }

                setDisplayedText(nextText);
                setIndex((prev) => prev + 1);
                if (onType) {
                    onType();
                }
            }, speed);
        } else {
            clearTimeout(intervalRef.current);
        }
        return () => clearTimeout(intervalRef.current);
    }, [index, text, speed, onType, displayedText]);

    return (
        <span className={isClassActive ? 'typewriter-span' : ''}>
            {isTyping ? (
                <>
                    The extraordinary writes <img src="https://cdn.pixabay.com/animation/2022/07/29/03/42/03-42-05-37_512.gif" alt="Loading..." style={{ width: '20px', height: '20px' }} />
                </>
            ) : (
                displayedText
            )}
        </span>
    );
};

const CentralComponent = ({ allCards, selectedCardIndex, setSelectedCardIndex }) => {
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [typingIndex, setTypingIndex] = useState(null);
    const [shownChats, setShownChats] = useState([]);
    const [loading, setLoading] = useState(false);
    const chatContainerRef = useRef(null);
    const { walletAddress } = useContext(WalletContext);

    const loadChatHistory = async (imageUri) => {
        setLoading(true);
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${Cookies.get('nft_utoken')}`);
        headers.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            publicKey: walletAddress,
            image_uri: imageUri
        });

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        try {
            const response = await fetch("https://api.zonexlegacy.com/nft/nftChatHistory", requestOptions);
            const result = await response.json();
            setChatHistory(result);
            setShownChats(result.map(() => true));
        } catch (error) {
            console.error("Error loading chat history:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedCardIndex !== null && allCards[selectedCardIndex]) {
            loadChatHistory(allCards[selectedCardIndex].image_uri);
        }
    }, [selectedCardIndex]);

    useEffect(() => {
        const handleClick = (event) => {
            if (event.target.closest('.click')) {
                setTypingIndex(null);
                setLoading(true); 
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    });

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            await sendMessage();
        }
    };

    const sendMessage = async () => {
        if (message.trim() === '') return;

        const newQuestion = { question: message, answer: '', avatar: 'path_to_avatar', timestamp: new Date().toISOString() };
        const newIndex = chatHistory.length;
        setChatHistory((prevHistory) => [...prevHistory, newQuestion]);
        setMessage('');
        setTypingIndex(newIndex);
        setShownChats((prevShownChats) => [...prevShownChats, false]);

        const response = await startChat(message);
        setChatHistory((prevHistory) => {
            const updatedHistory = [...prevHistory];
            updatedHistory[newIndex] = { ...newQuestion, answer: response.answer };
            return updatedHistory;
        });
    };

    const startChat = async (question) => {
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${Cookies.get('nft_utoken')}`);
        headers.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            publicKey: walletAddress,
            image_uri: allCards[selectedCardIndex]?.image_uri,
            question: question,
            name: allCards[selectedCardIndex]?.name
        });

        const requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch("https://api.zonexlegacy.com/nft/nftChat", requestOptions);
        const result = await response.json();
        return result;
    };

    const selectedCard = allCards[selectedCardIndex];

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    const handleType = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    return (
        <div className="chat-container col-md-6 d-flex align-items-center justify-content-center">
            <div className="w-100">
                <h2 className="tf-title-heading ct style-2 mg-bt-12 text-center">Chat</h2>
                {selectedCard && (
                    <div className="sc-card-product card-color">
                        <div className="card-body text-center">
                            <h4 className="card-title mt-4 justify-content-center">Start Your Chat With </h4>
                            <div className="card-media">
                                <img src={selectedCard.image_uri} alt={selectedCard.name} />
                            </div>
                            <h4 className="card-title mt-4 justify-content-center">{selectedCard.name}</h4>
                            <p className="card-text ">{selectedCard.description}</p>
                        </div>
                        <div className="chat-history" ref={chatContainerRef}>
                            {loading ? (
                                <p>Loading...</p>
                            ) : chatHistory.length === 0 ? (
                                <p className="no-chat-history">Please write a message to your NFT</p>
                            ) : (
                                chatHistory.map((chat, index) => (
                                    <div key={index} className="chat-message">
                                        <p className="chat-question" style={{ textAlign: 'left' }}>{chat.question}</p>
                                        <div className={`chat-answer-container ${typingIndex === index ? 'typing' : ''}`}>
                                            <p className="chat-answer" style={{ textAlign: 'left' }}>
                                                {shownChats[index] ? (
                                                    chat.answer
                                                ) : (
                                                    <Typewriter text={chat.answer} speed={50} onType={handleType} />
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="chat-box mt-50 w-100 chat-input-area">
                            <textarea
                                placeholder="Message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={handleKeyDown}
                                style={{ width: '90%' }}
                            ></textarea>
                            <button className="chat-send-button" onClick={sendMessage}>
                                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                )} 
            </div>
        </div>
    );
};

const RightColumn = ({ cards, selectCard, offset }) => (
    <div className="col-md-3">
        <h2 className="tf-title-heading ct style-2 mg-bt-12">Generals</h2>
        <div className="right-column-scroll">
            {cards.map((card, index) => (
                <div key={index} className="sc-card-product card-color mb-5">
                    <div className="card-media">
                        <img src={card.image} alt={card.title} />
                    </div>
                    <div className="card-body">
                        <h4 className="card-title">{card.title}</h4>
                        <p className="card-text mb-4">{card.description}</p>
                        <a href={card.link} target="_blank" rel="noopener noreferrer" className="sc-button style note fl-button pri-3 no-bg">
                            <span>Magic Eden</span>
                        </a>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

const TelepathyRoomPage = () => {
    const [myCards, setData] = useState([]);
    const [selectedCardIndex, setSelectedCardIndex] = useState(0);
    const { isLoggedIn, walletAddress, login } = useContext(WalletContext);
    const [isLeftColumnVisible, setIsLeftColumnVisible] = useState(true);

    useEffect(() => {
        if (walletAddress) {
            async function fetchData() {
                const result = await fetchMyCards(walletAddress);
                setData(result);
            }
            fetchData();
        }
    }, [walletAddress]);

    const userVerified = localStorage.getItem('userVerified') === 'true';
    const swiperRef = useRef(null);
    const presetCards = shuffleArray([
        { title: "General #99", description: "", image: "https://gateway.pinata.cloud/ipfs/QmdZEVS5EwTX1gxfb6FQD3TRZZGtBUHVDbmcXhw4TWfFBx/388.png", link: "https://magiceden.io/item-details/9zPDmR1ynJBbKTgw9pNqq96wZKWDkFMQbNDdFXP5sYvu?name=General-%2399" },
        { title: "General #33", description: "", image: "https://img-cdn.magiceden.dev/rs:fit:640:0:0/plain/https://gateway.pinata.cloud/ipfs/QmQ3yRhSVeSJT8EzBjRTkWqEVXDRb4jXu8Tn5HsaQSbRUM/74.png", link: "https://magiceden.io/item-details/2nvftNQwpXgLAcLdDUBzYrMapxBdeKHCdr8wi5dCGVHo?name=General-%2333" },
        { title: "General #222", description: "", image: "https://img-cdn.magiceden.dev/rs:fit:640:0:0/plain/https://gateway.pinata.cloud/ipfs/QmNjm8q5LCqo2AXyqcvVFEwnhUEEN3pEJ6DaACEep5VXh6/47.png", link: "https://magiceden.io/item-details/9g9a8nb3cdaAaD6jZDnczvKWCxBkVzJPNge8pxi6L1hS?name=General-%23222" },
        { title: "General #111", description: "", image: "https://img-cdn.magiceden.dev/rs:fit:640:0:0/plain/https://gateway.pinata.cloud/ipfs/QmdRTqVYdk3QtUvPDSfbG1Ng2rE2BVVghbdXof9KuYrFDo/25.png", link: "https://magiceden.io/item-details/8LsQioTo3nBGusMSq6H45akGXW8HwFMXqwxfTyk6eAeL?name=General-%23111" },
        { title: "General #77", description: "", image: "https://img-cdn.magiceden.dev/rs:fit:640:0:0/plain/https://gateway.pinata.cloud/ipfs/Qmca4NfZphcSysHFdKU26Sm7MqhgRACkytu7LHWT6xVFJd/18.png", link: "https://magiceden.io/item-details/8X26bPLexUpWBUKw4FzW9bR2CWL2fycyavvEGaqAmUfm?name=General-%2377" },
    ]);

    const notVerifiedCards = [
        { title: "You are Not Verified", description: "Verify that you have ZoneX NFT", image: "https://via.placeholder.com/300x300" },
    ];

    const leftColumnCards = userVerified && isLoggedIn ? myCards : notVerifiedCards;
    const rightColumnCards = userVerified && isLoggedIn ? presetCards : notVerifiedCards;
    const allCards = userVerified && isLoggedIn ? [...myCards, ...presetCards] : [...notVerifiedCards, ...notVerifiedCards];

    const handleCardSelection = (index) => {
        setSelectedCardIndex(index);
    };
 

    const nftUtoken = getCookie('nft_utoken');
    const walletAddress_ = localStorage.getItem('walletAddress');

    return (
        <div className="terminal-page-container">
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Telepathy Room</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-contact tf-section terminal-container">
                <div className="themesflat-container">
                    <div className="row align-items-start">
                        {nftUtoken && walletAddress_ && userVerified ? (
                            leftColumnCards.length > 0 ? (
                                <>
                                     <LeftColumn cards={leftColumnCards} selectCard={handleCardSelection} selectedCardIndex={selectedCardIndex} isVisible={isLeftColumnVisible} setIsLeftColumnVisible={setIsLeftColumnVisible} />
                                    <CentralComponent
                                        allCards={allCards}
                                        selectedCardIndex={selectedCardIndex}
                                        setSelectedCardIndex={setSelectedCardIndex}
                                        swiperRef={swiperRef}
                                    />
                                    <RightColumn cards={rightColumnCards} selectCard={handleCardSelection} offset={6} />
                                </>
                            ) : (
                                <div className="col-md-12 text-center">
                                    <p>There are no cards to display</p>
                                </div>
                            )
                        ) : (
                            <div className="col-md-12 text-center">
                                <p>You are not logged in. Please log in to view your NFTs.</p>
                                <button onClick={login} className="sc-button header-slider style style-1 wallet fl-button pri-1">
                                    <span>Wallet connect</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

async function fetchMyCards(walletAddress) {
    try {
        const headers = new Headers();
        headers.append("Authorization", "Bearer " + Cookies.get('nft_utoken'));
        headers.append("Content-Type", "application/json");

        const raw = JSON.stringify({ "publicKey": walletAddress });

        const requestOptions = {
            method: "POST",
            credentials: 'include',
            headers: headers,
            body: raw,
            redirect: "follow"
        };

        const response = await fetch("https://api.zonexlegacy.com/nft/nftReadAllWallet", requestOptions);
        const result = await response.json();
        if (result.success && result.result.length > 0) {
            return result.result;
        }
    } catch (error) {
        console.error(error);
    }
    return [];
}

const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
};

export default TelepathyRoomPage;
