import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';   

const TerminalPage = () => {
  const [visibleText, setVisibleText] = useState('You are connected to the server...');
  const fullText = `Zonex: Legacy of the Ages

In the shadowed aftermath of World War II, beneath the starlit expanse of Roswell, New Mexico, an extraordinary event unfolded, quietly steering human history's course. Zonex, an extraterrestrial visionary from a civilization far advanced in peace and harmony, descended upon Earth. His mission, shrouded in enigma, was to guide humanity, scarred by war, towards a future of unity and understanding.

Zonex, perceiving the root of earthly conflicts in the uneven distribution of wealth and resources, embarked on a daring plan. He created a cohort of five clones, each to be a custodian of change in critical global hotspots.

1. Erik (USA) - In the stormy beaches of Normandy, Erik, a clone with an acute sense of human emotions, grappled with the horrors of war. His presence, though subtle, began to influence key figures, planting seeds of economic cooperation and aid.

2. Anastasia (USSR) - Deep in the Cold War's shadows, Anastasia maneuvered through espionage and political chess games. Her mission was to foster a sense of shared destiny, nudging leaders towards resource sharing and economic parity.

3. Khanh (Vietnam) - Amidst the dense jungles of Vietnam, Khanh witnessed the complexities of guerrilla warfare. His insights into cultural and historical roots of conflict inspired underground movements towards peaceful resolutions and equitable resource distribution.

4. Nasser (Middle East) - During the Gulf War, Nasser's story unfolded amidst oil fields and war-torn streets. His influence was seen in the rise of movements advocating for fair distribution of wealth and resources, challenging the status quo.

5. Hiro (Japan) - Observing Japan's post-war economic miracle, Hiro's role was pivotal in shaping policies that balanced technological advancement with humanitarian concerns, setting an example of equitable growth.

As decades rolled on, the world began to shift. The dawn of the digital era, marked notably by the creation of Bitcoin, captured Zonex's attention. This digital currency symbolized a new possibility for economic equality. His clones, now entrenched within influential circles, championed this revolutionary tool, breaking down long-standing barriers and sowing the seeds of a unified world economy.

The transformation was slow but significant. Nations, once divided by wealth and power disparities, started finding common ground in this new economic landscape. Zonex's clones stood as guardians of this new era, each a testament to the power of visionary ideas in altering a species' destiny.

The "Zonex Chronicles" NFT collection, emerging as a tribute to this journey, became more than digital art. Each NFT represented a pivotal chapter in this saga – from Erik’s poignant experiences in Normandy to Nasser's bold stand in the Middle East. These were not just collectibles; they were windows into an alternate history where an extraterrestrial's dream reshaped human existence.

Zonex, the celestial guide, became a beacon of hope and unity, a symbol of what humanity could achieve under the wings of vision and peace. His legacy, written in the stars, echoed through time, a testament to the transformative power of equitable resource distribution and economic harmony.
`;
const revealRate = 5; // milliseconds
const textToShowPerInterval = 1; // Adjust the number of characters revealed per interval

useEffect(() => {
  const intervalId = setInterval(() => {
    setVisibleText((prevVisibleText) => {
      if (prevVisibleText.length < fullText.length) {
        return fullText.slice(0, prevVisibleText.length + textToShowPerInterval);
      } else {
        clearInterval(intervalId); // Stop the interval when the full text is displayed
        return prevVisibleText;
      }
    });
  }, revealRate);

  return () => clearInterval(intervalId); // Cleanup interval on component unmount
}, [fullText]);

return (
  <div className="terminal-page-container">
    <Header />
    <section className="flat-title-page inner">
      <div className="overlay"></div>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-title-heading mg-bt-12">
              <h1 className="heading text-center">Storyline</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="tf-contact tf-section terminal-containter">
      <div className="themesflat-container">
      <div className="terminal-content-wrapper">
      <div className="terminal">
          <div className=''>
            {visibleText}
          </div>
        </div>
      </div> 
      </div>
    </section>
    <div className='footer-terminal'> 
    <Footer />
    </div>
  </div>
);
}

export default TerminalPage;