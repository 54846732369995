import React from 'react'; 
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const Cookies = () => {
    return (
        <div className='terminal-page-container'>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Cookies</h1>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-contact tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="terminal"> 
                                <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
                                <p className="mb-5">Welcome to Zonex Legacy. Like many other websites, we use cookies to enhance your browsing experience, gather insights into our audience, and ensure the efficient functioning of our site. This Cookies Policy provides detailed information about how we use cookies and how you can manage them.</p>
                                
                                <h2 className="text-2xl font-semibold mb-2">2. What Are Cookies?</h2>
                                <p className="mb-5">Cookies are small text files placed on your device when you visit a website. They are widely used to 'remember' you and your preferences, either for a single visit (through a 'session cookie') or for multiple repeat visits (using a 'persistent cookie'). Cookies ensure a consistent and efficient experience for visitors and perform essential functions such as allowing users to register and remain logged in.</p>
                                
                                <h2 className="text-2xl font-semibold mb-4">3. Use of Cookies on Our Site</h2>
                                <h4 className="mb-3">We use the following types of cookies:</h4>
                                
                                    <p><strong> &#x2022; Improve User Experience:</strong> Cookies help us remember your settings (such as language preferences) and provide personalized content.</p>
                                    <p><strong> &#x2022; Analytics and Performance:</strong> We utilize cookies to gather data that helps us understand how our site is being used and how we can improve it. This includes which pages are most frequently visited and how visitors navigate the site.</p>
                                    <p><strong> &#x2022; Functionality:</strong> Some cookies are essential to the functionality of our website, allowing seamless navigation and the use of our features.</p>
                                    <p><strong> &#x2022; Marketing:</strong> These cookies record the visits of users and the links they have gone through. They are used to deliver advertising messages that are relevant to the interests of different users and can also limit the number of ad impressions.</p>
                                    <p className="mb-5"><strong> &#x2022; Geo-location:</strong> These cookies collect information about your location based on your IP address, Wi-Fi, or network connection. They are used to provide personalized content, such as showing you news relevant to your area or tailoring advertisements based on your location. They help improve your browsing experience by allowing websites to serve more relevant content and ads specific to your geographic area.</p>
                                 
                                
                                <h2 className="text-2xl font-semibold mb-4">4. Types of Cookies We Use</h2>
                                <p><strong> &#x2022; Session Cookies: </strong> These are temporary cookies that expire once you close your browser.</p>
                                <p><strong> &#x2022; Persistent Cookies: </strong> Persistent cookies remain on your device until they expire or are deleted. They remember your preferences and actions across the site.</p>
                                <p className="mb-5"><strong> &#x2022; Third-Party Cookies: </strong> We may use third-party service providers who set cookies to perform services on our behalf, such as analytics or advertising.</p>
                                
                                <h2 className="text-2xl font-semibold mb-4">5. Managing Cookies</h2>
                                <p className="mb-5">Most web browsers allow some control of cookies through the browser settings. You can set your browser to refuse cookies or delete them after they have been placed. However, please note that doing so might affect the functionality of our site.</p>
                                
                                <h2 className="text-2xl font-semibold mb-4">6. Consent</h2>
                                <p className="mb-5">By using the Zonex Legacy website, you consent to the use of cookies in accordance with this Cookies Policy.</p>
                                
                                <h2 className="text-2xl font-semibold mb-4">7. Changes to Our Cookies Policy</h2>
                                <p className="mb-5">We may update this Cookies Policy from time to time. Any changes will be posted on this page with an updated revision date.</p>
                                
                                <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
                                <p className="mb-5">If you have any questions about our use of cookies, please contact us at support@zonexlegacy.com.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Cookies;
