import img1 from '../images/box-item/NewGeneralHeadline.png';
import img2 from '../images/box-item/ufobaner.png';
import imgbg1 from '../images/slider/bg_slide_1.png'


const heroSliderData = [
    {
        title_1: "Discover, Find ",
        title_2: "Extraordinary",
        title_3: "ZoneX NFTs",
        description: "",
        img: img1,
        img2: img2,
        imgbg: imgbg1,
        class:'left'
    },
    
]

export default heroSliderData;