import './polyfills';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { WalletProvider } from './context/WalletContext';

// Initial render: Wraping the App component with Buffer, BrowserRouter, ScrollToTop, and WalletProvider
const container = document.getElementById('root');

if (container) {
    const root = createRoot(container); // Create a root.
    root.render(
      <BrowserRouter>
        <ScrollToTop />
        <WalletProvider>
          <App />
        </WalletProvider>
      </BrowserRouter>
    );
} else {
    console.error('Failed to find the root container');
}