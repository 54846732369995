const menus = [
     
    {
        id: 2,
        name: 'Storyline',
        links: '/storyline',
    },
    {
        id: 3,
        name: 'Telepathy Room',
        links: '/telepathy-room', 
    }, 
    {
        id: 4,
        name: 'Marketplace',
        links: 'https://marketplace.zonexlegacy.com/', 
    }, 
    {
        id: 5,
        name: 'Covert Missions',
        links: '/covert-missions', 
    }, 
    {
        id: 6,
        name: 'Roadmap',
        links: '/roadmap', 
    },
    {
        id: 7,
        name: 'AIR DROP',
        links: '/airdrop', 
    }
]

export default menus;