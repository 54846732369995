import React , { useState } from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Accordion } from 'react-bootstrap-accordion'

const FAQ = () => {
    const [data] = useState(
       
        [
            {
                "key": "0",
                "show": "show",
                "title": "What is Zonex Legacy?",
                "text": "Zonex Legacy is a unique digital art collection on the Solana network, featuring a series of interactive Non-Fungible Tokens (NFTs) with an alien-themed narrative."
            },
            {
                "key": "1",
                "title": "How do I purchase a Zonex Legacy NFT?",
                "text": "You can purchase Zonex Legacy NFTs through our marketplace using a digital wallet compatible with the Solana network. Follow the instructions on our website to get started."
            },
            {
                "key": "2",
                "title": "What are the different ranks of NFTs in Zonex Legacy?",
                "text": "NFTs in Zonex Legacy are categorized into ranks: Soldier, Sergeant, Officer, Lieutenant, Colonel, and General, each with unique features and privileges."
            },
            {
                "key": "3",
                "title": "What is the royalty fee for Zonex Legacy NFTs?",
                "text": "A 7% royalty fee is applied to each NFT sale, which is allocated to the original creator of the NFT."
            },
            {
                "key": "4",
                "title": "Can I sell my Zonex Legacy NFT on other marketplaces?",
                "text": "Yes, you can list your Zonex Legacy NFTs for sale on other marketplaces. However, remember that the royalty fee will still apply to each sale."
            },
            {
                "key": "5",
                "title": "How does the Telepathy Room work?",
                "text": "The Telepathy Room is an interactive feature where NFT owners can communicate with their NFTs. The number of messages per day depends on the rank of the NFT."
            },
            {
                "key": "6",
                "title": "Are there any future updates planned for Zonex Legacy?",
                "text": "Yes, we have several exciting updates in the pipeline, including enhanced Telepathy Room functionality, the introduction of Covert Missions, and the unveiling of Phase 2. Keep an eye on our roadmap for more details."
            },
            {
                "key": "7",
                "title": "How can I participate in the community drop?",
                "text": "Stay tuned to our official channels for announcements on community drops. Ensure you're signed up and actively participating in our community for a chance to be involved."
            },
            {
                "key": "8",
                "title": "Is there customer support for new users?",
                "text": "Absolutely! If you have any questions or need assistance, please contact our support team at support@zonexlegacy.com."
            },
            {
                "key": "9",
                "title": "How can I stay updated with Zonex Legacy news and events?",
                "text": "Join our community on social media and subscribe to our newsletter to stay informed about the latest news, updates, and events."
            }
        ]
        
        
    )
    return (
        <div className='terminal-page-container'>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">FAQ</h1>
                            </div> 
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-section wrap-accordion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">
                                Frequently Asked Questions
                            </h2>
                            {/*
                            <h5 className="sub-title help-center mg-bt-32 ">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.
                            </h5>
                            */}
                        </div>
                        <div className="col-md-12">
                            <div className="flat-accordion2">
                                {
                                    data.map((item,index) => (
                                        <Accordion key={index} title={item.title} >
                                            <p>{item.text}</p>
                                        </Accordion>
                                    ))
                                }                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default FAQ;
