import React from 'react'; 
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const Error404 = () => {
    return (
        <div>
            <Header />
            <section className="flat-title-page inner"></section>
            <section className="tf-contact tf-section fill-viewport">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="flat-form">                       
                                <h1 className="tf-title-heading ct style-2 mg-bt-12">
                                    Error 404
                                </h1>
                                <h3 className="sub-title ct style-1 pad-0-15">
                                    Looks like the page you're searching for has gone missing. Head back to our headquarters or let us know if you need a hand
                                </h3> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Error404;
