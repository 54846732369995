import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function decodeHtmlEntities(str) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = str;
  return textarea.value;
}

function truncateHTML(html, maxLength) {
  const div = document.createElement('div');
  div.innerHTML = html;

  let truncatedText = div.innerText || div.textContent;
  if (truncatedText.length > maxLength) {
    truncatedText = truncatedText.substring(0, maxLength) + '...';
  }

  return truncatedText;
}

const Blog = () => {
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(6);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const postsResponse = await fetch('https://admblog.zonexlegacy.com/wp-json/wp/v2/posts');
                const postsData = await postsResponse.json();
                
                const mediaResponses = await Promise.all(postsData.map(post => 
                    fetch(`https://admblog.zonexlegacy.com/wp-json/wp/v2/media/${post.featured_media}`)
                ));
                const mediaData = await Promise.all(mediaResponses.map(res => res.json()));
                
                const postsWithMedia = postsData.map(post => {
                    const media = mediaData.find(m => m.id === post.featured_media);
                    return {
                        ...post,
                        featured_media_url: media ? media.source_url : 'default-image-url.jpg'
                    };
                });
                
                setData(postsWithMedia);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 3);
    }

    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Covert Missions</h1>
                            </div> 
                        </div>
                    </div>
                </div>                    
            </section>
            <div className="tf-section sc-card-blog dark-style2">
                <div className="themesflat-container">
                    {loading ? (
                        <div className="loading-container text-center">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                            <p>Loading...</p>
                        </div>
                    ) : (
                        <div className="row">
                            {
                                data.slice(0, visible).map((item, index) => (
                                    <BlogItem key={index} item={item} />
                                ))
                            }
                            {
                                visible < data.length && 
                                <div className="col-md-12 wrap-inner load-more text-center"> 
                                    <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3 mt-6" onClick={showMoreItems}><span>Load More</span></Link>
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

const BlogItem = (props) => {
    const decodedTitle = decodeHtmlEntities(props.item.title.rendered);
    const truncatedContent = truncateHTML(props.item.content.rendered, 255);

    return (
        <div className="fl-blog fl-item2 col-lg-4 col-md-6">
            <article className="sc-card-article">
                <div className="card-media">
                    <Link to={`/covert-mission-details/${props.item.id}`}><img src={props.item.featured_media_url} alt="Blog" /></Link>
                </div>  
                <div className="text-article">
                    <h3><Link to={`/covert-mission-details/${props.item.id}`}>{decodedTitle}</Link></h3>
                    <h6><div dangerouslySetInnerHTML={{ __html: truncatedContent }}></div></h6>
                </div>
                <Link to={`/covert-mission-details/${props.item.id}`} className="sc-button fl-button pri-3"><span>Read More</span></Link>
            </article>
        </div>
    );
}

export default Blog;
