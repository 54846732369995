import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import icon1 from '../../../assets/images/tempnfts/Soldier.png' 
import icon2 from '../../../assets/images/tempnfts/Sergeant.png' 
import icon3 from '../../../assets/images/tempnfts/Officer.png' 
import icon4 from '../../../assets/images/tempnfts/Lieutenant.png' 
import icon5 from '../../../assets/images/tempnfts/Colonel.png' 
import icon6 from '../../../assets/images/tempnfts/General.png' 
import hovericon1 from '../../../assets/images/tempnfts/1.png' 
import hovericon2 from '../../../assets/images/tempnfts/2.png' 
import hovericon3 from '../../../assets/images/tempnfts/3.png' 
import hovericon4 from '../../../assets/images/tempnfts/4.png' 
import hovericon5 from '../../../assets/images/tempnfts/5.png' 
import hovericon6 from '../../../assets/images/tempnfts/6.png' 

const Create = () => {
    const data = [
        
        {
            title: "Soldier",
            description: "Silent protectors in stealth armor, Soldiers covertly eliminate threats to Earth.",
            icon : icon1,
            hovericon : hovericon1,
            colorbg : "icon-color4",
        },
        {
            title: "Sergeant",
            description: "Leaders in stealth, Sergeants guide squads in covert operations for Earth's defense.",
            icon : icon2,
            hovericon : hovericon2,
            colorbg : "icon-color3",
        },
        {
            title: "Officer",
            description: "Strategists of Earth's defense, Officers plan operations with alien and human tech.",
            icon : icon3,
            hovericon : hovericon3,
            colorbg : "icon-color4",
        }, 
        {
            title: "Lieutenant",
            description: "Leading critical missions, Lieutenants use advanced alien tech to safeguard Earth in secret.",
            icon : icon4,
            hovericon : hovericon4,
            colorbg : "icon-color3",
        }, 
        {
            title: "Colonel",
            description: "Commanding with strategic genius, Colonels impact the hidden war to protect humanity.",
            icon : icon5,
            hovericon : hovericon5,
            colorbg : "icon-color2",
        },
        {
            title: "General",
            description: "Generals, with strategic insight and alien tech, covertly shape Earth's fate from the shadows.",
            icon : icon6,
            hovericon : hovericon6,
            colorbg : "icon-color1"
        },
    ]
    return (
        <section className="tf-box-icon create tf-section  primary-bg">
            <div className="themesflat-container"> 
                <div className="row">
                    <div className="col-md-12">
                        <div className="rank-heading">
                            <h2 className="tf-title ">The Elite Clones of ZoneX: From Soldiers to Generals</h2>
                            <div className='heading-line'></div> 
                        </div>
                    </div>
                </div> 
                <div className='row'>
                    {
                        data.map((item , index) => (
                            <CreateItem key={index} item={item} />
                        ))
                    }
                </div>             
            </div>
        </section>
    );
}


const CreateItem = props => {
    // State to manage hover status
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div 
            className='col-lg-2 col-md-6 col-12'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="sc-box-icon">
                <div className="image center">
                    <div className={`icon-create ${props.item.colorbg}`}>
                        <img src={isHovered ? props.item.hovericon : props.item.icon} alt={props.item.title} />
                    </div>                                                                             
                </div>
                <h3 className="heading"><Link to="/storyline">{props.item.title}</Link></h3>
                <p className="">{props.item.description}</p>
            </div>
        </div>
    );
}


export default Create;
