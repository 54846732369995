import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";

const CardModal = (props) => {
    
return (

    <Modal
    show={props.show}
    onHide={props.onHide}
  >
    <Modal.Header closeButton></Modal.Header>

    <div className="modal-body space-y-20 pd-40">
        <h3>Confirm Order</h3> 
        <div className="d-flex justify-content-between">
            <p> Total amount:</p>
            <p className="text-right price color-popup"> 0.4 SOL </p>
        </div>
        <div className="hr"></div> 
        <Link to="/wallet-connect" className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Buy</Link>
    </div>
    </Modal>
    
  );
};

export default CardModal;
