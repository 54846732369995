import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import img from '../../../assets/images/box-item/500x650.png'  

const Community = () => {

    return (
        <div>
            <section className=" tf-section primary-colour">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <h2 className="tf-title ">Community</h2>
                                <div className='heading-line'></div> 
                            </div>
                        </div>
                    </div> 
                    <div className="themesflat-container"> 
                    <div className="d-flex align-items-start">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12 community-padding">
                            <h2 className="tf-title-heading style-2 mg-bt-12">
                                Short description
                            </h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vestibulum malesuada mi, a suscipit turpis eleifend a. Aliquam ex ex, tempus pellentesque elementum non, mollis sed libero. Mauris sodales ex massa, in placerat justo rhoncus a. Etiam a ex neque. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vivamus at sollicitudin orci. Suspendisse maximus ex eu lorem consectetur lacinia ac sed lorem.   </p> 
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 text-center">
                            <div className="box-feature-contact margin-img-community">
                                <img src={img} alt="Axies" className="img-fluid" />
                            </div>
                            <div className="center">
                                <div className="meta-info style2">
                                    <a href="/explore-01" className="sc-button header-slider style rocket fl-button pri-3 main-btn no-bg"><span>Join The Community Now</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 community-padding">
                            <h2 className="tf-title-heading style-2 mg-bt-12">
                                Why to join
                            </h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vestibulum malesuada mi, a suscipit turpis eleifend a. Aliquam ex ex, tempus pellentesque elementum non, mollis sed libero. Mauris sodales ex massa, in placerat justo rhoncus a. Etiam a ex neque. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vivamus at sollicitudin orci. Suspendisse maximus ex eu lorem consectetur lacinia ac sed lorem.   </p> 
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Community;
