import React, { createContext, useState, useEffect } from 'react';
const web3 = require('@solana/web3.js');
const bs58check = require('bs58check');
const Cookies = require('js-cookie');

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [walletAddress, setWalletAddress] = useState(null);

    useEffect(() => {
        checkAuthentication();
    }, []);

    const checkAuthentication = async () => {
        if (Cookies.get('nft_utoken')) {
            const phantom = window.solana;
            if (phantom) {
                try {
                    const response = await phantom.connect();
                    if (response.publicKey) {
                        const newWalletAddress = response.publicKey.toString();
                        setWalletAddress(newWalletAddress);
                        await verifyJwtToken(newWalletAddress);
                    } else {
                        console.error("Failed to connect to Phantom Wallet:");
                    }
                } catch (error) {
                    console.error("Failed to connect to Phantom Wallet:", error);
                }
            } else {
                console.error("Phantom Wallet not found!");
            }
        }
    };

    const verifyJwtToken = async (walletAddress) => {
        let headers = new Headers();
        headers.append("Authorization", "Bearer " + Cookies.get('nft_utoken'));
        headers.append("Content-Type", "application/json");

        let raw = JSON.stringify({ "publicKey": walletAddress });

        var requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        try {
            const response = await fetch("https://api.zonexlegacy.com/nft/nftJwtverify", requestOptions);
            const data = await response.json();
            if (data.result) {
                setIsLoggedIn(true);
            } else {
                Cookies.remove('nft_utoken');
                setIsLoggedIn(false);
            }
        } catch (error) {
            console.error('Error verifying JWT token:', error);
        }
    };

    const connectWallet = async () => {
        try {
            if (window.solana && window.solana.isPhantom) {
                const phantom = window.solana;
                let response = await phantom.connect();
                if (response.publicKey) {
                    const newWalletAddress = response.publicKey.toString();
                    setWalletAddress(newWalletAddress);
                    localStorage.setItem('walletAddress', newWalletAddress);
                    await authenticateWallet(response.publicKey.toString(), phantom);
                } else {
                    console.error("Failed to connect to Phantom Wallet.");
                }
            } else {
                console.error("Phantom Wallet not found!");
            }
        } catch (error) {
            console.error("Failed to connect to Phantom Wallet:", error);
        }
    };

    const authenticateWallet = async (walletAddress, phantom) => {
        const message = "To avoid digital dognappers & use our services, sign below to authenticate.";
        const encodedMessage = new TextEncoder().encode(message);
        const signedMessage = await phantom.signMessage(encodedMessage, "utf8");

        fetch('https://api.zonexlegacy.com/nft/verifySigWallet', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                message: message,
                publicKey: walletAddress,
                signature: bs58check.encode(signedMessage.signature)
            })
        }).then(response => response.json())
            .then(data => {
                if (data && data.result && data.token) {
                    Cookies.set('nft_utoken', data.token, { expires: 30 });
                    setIsLoggedIn(true);
                    localStorage.setItem('userVerified', 'true');
                } else {
                    console.error('Signature verification failed.');
                    localStorage.setItem('userVerified', 'false');
                }
            })
            .catch(error => {
                console.error('Error verifying signature:', error);
            });
    };

    const logout = () => {
        Cookies.remove('nft_utoken');
        setIsLoggedIn(false);
        setWalletAddress(null);
    };

    return (
        <WalletContext.Provider value={{ login: connectWallet, logout, isLoggedIn, walletAddress }}>
            {children}
        </WalletContext.Provider>
    );
};
