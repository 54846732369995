import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Roadmap from "../components/layouts/new-home/Roadmap";

const PrivacyPolicy = () => {
  return (
    <div className="terminal-page-container">
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">ZoneX Legacy Roadmap: A Journey Through the Stars</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-contact tf-section">
        <div className="themesflat-container ">
          <div className="row">
            <div className="col-12">
              <div className="roadmap-page">
                <Roadmap />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
