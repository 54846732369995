import React , { useState , Fragment, useRef } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // basic Swiper styles
import 'swiper/css/navigation'; // for navigation (arrows)
import 'swiper/css/pagination'; // for pagination (dots)
import SwiperCore, { Navigation, Pagination } from 'swiper';
import img from '../assets/images/box-item/testalien.png'

  
const CovertMissions = () => {

  return (
    <div className="terminal-page-container">
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Covert Missions</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-contact tf-section terminal-container">
        <div className="themesflat-container">
          <div className="row align-items-start"> 

          </div>
        </div>
      </section> 
      <Footer />
    </div>
  );
}

export default CovertMissions;