import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function decodeHtmlEntities(str) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = str;
  return textarea.value;
}

const BlogDetails = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const postResponse = await fetch(`https://admblog.zonexlegacy.com/wp-json/wp/v2/posts/${id}`);
                const postData = await postResponse.json();
                
                const mediaResponse = await fetch(`https://admblog.zonexlegacy.com/wp-json/wp/v2/media/${postData.featured_media}`);
                const mediaData = await mediaResponse.json();
                
                setPost({
                    ...postData,
                    featured_media_url: mediaData.source_url
                });
                setLoading(false);
            } catch (error) {
                console.error('Error fetching post data:', error);
                setLoading(false);
            }
        };

        fetchPost();
    }, [id]);

    return (
        <div>
            <Header /> 
            {loading ? (
                <div className="tf-section post-details">
                    <div className="themesflat-container">
                        <div className="style">
                            <div className="post">
                                <div className="loading-container text-center">
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                    <p>Loading...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                post && (
                    <div className="tf-section post-details">
                        <div className="themesflat-container">
                            <div className="style">
                                <div className="post">
                                    <div className="inner-content">
                                        <h1 className="heading text-center">{decodeHtmlEntities(post.title.rendered)}</h1>
                                        <div className="divider"></div>
                                        <div className="meta-post flex mg-bt-31">
                                        </div>
                                        <div className="image">
                                            <img src={post.featured_media_url} alt="Blog" />
                                        </div> 
                                        <div className="inner-post mg-t-40">
                                            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
            <Footer />
        </div>
    );
}

export default BlogDetails;
