import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const Contact02 = () => {
    return (
        <div className='terminal-page-container'>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Contact</h1>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-contact tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="flat-form">
                                <h2 className="tf-title-heading ct style-2 mg-bt-12">
                                    Drop A Message
                                </h2>
                                <h5 className="sub-title ct style-1 pad-0-15">
                                    Have a question or want to share your thoughts? Reach out, and let's connect. 
                                </h5>
                                <div className="form-inner">
                                    <form id="contactform" noValidate="novalidate" className="form-submit">
                                        <input id="name" name="name" tabIndex="1" aria-required="true" required type="text" placeholder="Your Full Name" />
                                        <input id="email" name="email" tabIndex="2"  aria-required="true" required type="email" placeholder="Your Email Address" />
                                        <textarea id="message" name="message" tabIndex="3" aria-required="true" required placeholder="Message"></textarea>
                                        <button className="submit">Send message</button>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Contact02;
