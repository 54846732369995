import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import heroSliderData from '../assets/fake-data/data-slider';
import Create from '../components/layouts/new-home/Create';
import LiveAuction from '../components/layouts/new-home/LiveAuction';
import NewSlider from '../components/slider/NewSlider';
import StoryLine from '../components/layouts/new-home/StoryLine';
import Community from '../components/layouts/new-home/Community';
import Roadmap from '../components/layouts/new-home/Roadmap'; 
import TopSeller from '../components/layouts/home-3/TopSeller';
import topSellerData from '../assets/fake-data/data-top-seller';
import TodayPicks from '../components/layouts/new-home/TodayPicks';
import todayPickData from '../assets/fake-data/data-today-pick';
import PopularCollection from '../components/layouts/home-3/PopularCollection';
import popularCollectionData from '../assets/fake-data/data-popular-collection';

const Home = () => {
    return (
        <div className='home-3'>
            <Header />
            <NewSlider data={heroSliderData} />
            <Create />
            <StoryLine />
            {/* <LiveAuction data={liveAuctionData} /> */}
            {/* <Community /> */}
            {/* <Roadmap /> */}
            <Footer />
        </div>
    );
}

export default Home;
